@import "../../App.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.UserDataHolder {
  background-color: white;
  width: 800px;
  padding: 20px;
  position: relative;
}

.lineAroundUserData {
  border: 2px solid $secColor;
  padding: 15px;
}

.userIconInfo {
  display: flex;
  align-items: center;
}

.userIcon {
  width: 60px;
  margin-right: 10px;
}
.userEmail {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.subInfo {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  font-size: 19px;
  margin-top: 10px;
}

.subHolder {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  align-items: center;
  justify-content: center;
}

.btnHodler {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  margin-top: 50px;
  width: 45%;
}

.signoutBtn {
  cursor: pointer;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  padding: 10px;
  background-color: $secColor;
  border-radius: 30px;
  width: 110px;
  text-align: center;
  color: white;
  transition: 0.5s ease;
  z-index: 23;
}

.subEndBtn {
  width: 180px;
  margin-left: 30px;
}

.subInfoDetail {
  font-weight: 500;
}

.signoutBtn:hover {
  background-color: white;
  color: $secColor;
  border: 1px solid $secColor;
}

@media (max-width: 768px) {
  .UserDataHolder {
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    width: 100%;
    padding: 20px;
    position: relative;
  }

  .userIcon {
    width: 40px;
  }

  .userEmail {
    font-size: 18px;
  }

  .subInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    div {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
}
