body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "town bold";
  src: url("./fonts/Jason\ Vandenberg\ -\ Town\ 10\ Display\ Bold.otf");
}

@font-face {
  font-family: "town hair";
  src: url("./fonts/Jason\ Vandenberg\ -\ Town\ 10\ Display\ Hairline.otf");
}

@font-face {
  font-family: "town black";
  src: url("./fonts/Jason\ Vandenberg\ -\ Town\ 10\ Display\ Black.otf");
}

@font-face {
  font-family: "town light";
  src: url("./fonts/Jason\ Vandenberg\ -\ Town\ 10\ Display\ Light.otf");
}

@font-face {
  font-family: "town med";
  src: url("./fonts/Jason\ Vandenberg\ -\ Town\ 10\ Display\ Medium.otf");
}

@font-face {
  font-family: "town reg";
  src: url("./fonts/Jason\ Vandenberg\ -\ Town\ 10\ Display\ Regular.otf");
}
