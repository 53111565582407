@import "../../App.scss";

.realDiv {
  background-color: rgb(235, 235, 235);
  height: 100%;
}

.checkoutHeader {
  width: 100%;
  background-color: $priColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  margin-bottom: 30px;
}

.checkoutHeaderLogo {
  margin-left: 40px;
}

.checkoutLink {
  margin-right: 40px;
  text-decoration: none;
  color: white;
}

.checkoutText {
  font-size: 25px;
  margin-left: 50px;
  margin-bottom: 20px;
}

.checkoutDataHolder {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 1px 3px 1px rgb(196, 196, 196);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

.dataLeft {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.location {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 500;
}
.fName {
  width: 90%;
}

.lName {
  width: 90%;
}

.address {
  width: 95%;
}

.introSec {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.fNameHolder {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dataRight {
  padding: 30px;
  width: 45%;
  border: 1px solid rgb(201, 201, 201);
}

.summaryText {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 30px;
}

.summaryDetail {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.space {
  margin-bottom: 20px;
}

.line {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  background-color: rgb(179, 179, 179);
}

.payText {
  font-size: 22px;
  font-weight: 700;
  color: $priColor;
  margin-bottom: 40px;
}

.payBtn {
  width: 100%;
  height: 50px;
}

.error {
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
  color: red;
}

@media (max-width: 768px) {
  .checkoutHeaderLogo {
    width: 150px;
    margin-left: 20px;
  }

  .checkoutLink {
    margin-right: 20px;
    text-decoration: none;
    color: white;
  }

  .checkoutText {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .checkoutDataHolder {
    justify-content: space-between;
    flex-direction: column;
    background-color: white;
  }
  .dataLeft {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .dataRight {
    margin-top: 50px;
    padding: 30px;
    width: 95%;
    border: 1px solid rgb(201, 201, 201);
  }

  .summaryText {
    font-size: 20px;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 30px;
  }

  .payText {
    font-size: 18px;
    font-weight: 700;
    color: $priColor;
    margin-bottom: 40px;
  }
}
