@import "../../App";

.api-intro {
  font-family: "town bold";
  font-size: 25px;
  letter-spacing: 2px;
  width: 80%;
  margin-left: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}
.api-intro-line {
  margin-left: 30px;
  width: 100px;
  height: 4px;
  background-color: black;
  margin-bottom: 30px;
}
.api-sec-one {
  font-family: "town reg";
  font-size: 20px;
  letter-spacing: 2px;
  width: 90%;
  margin-left: 30px;
  line-height: 30px;
  margin-bottom: 30px;
}
.api-sec-two {
  font-family: "town reg";
  font-size: 20px;
  letter-spacing: 2px;
  width: 90%;
  margin-left: 30px;
  line-height: 30px;
  margin-bottom: 30px;
}

.api-sec-three {
  font-family: "town reg";
  font-size: 20px;
  letter-spacing: 2px;
  width: 90%;
  margin-left: 30px;
  line-height: 30px;
  margin-bottom: 30px;
}

.bold {
  font-family: "town bold";
}

.explain {
  font-size: 17px;
}

.api-sec-four {
  font-size: 16px;
  font-family: "town reg";
  letter-spacing: 1px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.url {
  background-color: $secColor;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
  width: 90%;
  margin-bottom: 30px;
  font-family: "Arial Narrow Bold", sans-serif;
  padding: 10px 0 10px 0;
}

.components-sec {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 50px;
}

.components {
  border: 2px solid black;
  width: 400px;
  height: 270px;
  font-size: 18px;
  font-family: "town reg";
  line-height: 25px;
  text-align: center;
  padding: 0 20px 0 20px;
  letter-spacing: 1px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .components {
    border: none;
  }
  .components-sec {
    flex-direction: column;
  }
  .api-intro {
    margin-top: 40px;
  }
}
