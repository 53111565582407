@import "../../App";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: 1s ease;
}

.scrolled {
  padding: 10px 0 10px 0;
  background-color: rgb(43, 43, 43);
  z-index: 10;
}

.scrolled .navLinks .user {
  fill: white;
}

.logo {
  margin-left: 60px;
  width: 294px;
  height: 65px;
  padding-top: 5px;
}

.user {
  fill: white;
}

.link {
  font-family: "town reg", sans-serif;
  text-decoration: none;
  color: #f2f2f2;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: 35px;
  cursor: pointer;
  transition: 0.5s ease;
}

.navLinks {
  display: flex;
  align-items: center;
}

.navLinks .link:hover {
  color: $secColor;
}

.userMenu {
  width: 60%;
  height: 65vh;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userMenu:focus {
  outline: none;
}

.loginMenu {
  background-color: #f2f2f2;
  width: 28%;
  margin-left: auto;
  margin-right: auto;
}

.loginMenu:focus {
  outline: none;
}

.navLinks a:nth-child(8) {
  padding: 8px 20px;
  border-radius: 40px;
  border: 2px solid $secColor;
  color: $secColor;
}
.navLinks a:nth-child(8):hover {
  background-color: $secColor;
  color: white;
}

.navLinks a:nth-child(8):focus {
  outline: none;
}

.active {
  color: $secColor;
}

.bgImg {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  overflow: hidden;
  object-fit: cover;
  height: 100vh;
}

.aboutbg {
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 1;
}

.technologybg {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  height: 100vh;
  object-fit: cover;
  opacity: 1;
}

.apibg {
  position: absolute;
  z-index: -1;
  top: 100px;
  right: 80px;
  height: 80vh;
  object-fit: cover;
  opacity: 1;
}

.callToActionText {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  margin-left: 80px;
  div {
    font-family: "town bold";
    font-size: 35px;
    letter-spacing: 1px;
    color: $priColor;
    span:nth-child(2) {
      color: $secColor;
    }
  }
}

.apiIntroduction {
  margin-top: 220px;
  margin-left: 80px;
  width: 500px;
}

.whereWorkHappens {
  font-family: "town bold";
  font-size: 45px;
  letter-spacing: 1px;
  color: $textColor;
  line-height: 35px;
  margin-bottom: 30px;
  line-height: 50px;
}

.whereWorkHappensDetials {
  font-family: "town reg";
  font-size: 15px;
  letter-spacing: 1px;
  color: $textColor;
  line-height: 30px;
}

.LastText {
  line-height: 20px;
  width: 35%;
  margin-left: 80px;
  color: $priColor;
  font-family: "town reg";
  font-size: 17px;
  margin-top: 30px;
  letter-spacing: 1px;
}

.vido {
  margin-top: 5px;
}

.LastTextSec {
  line-height: 20px;
  width: 35%;
  margin-left: 80px;
  color: $priColor;
  font-family: "town reg";
  font-size: 17px;
  margin-top: 10px;
  letter-spacing: 1px;
}

.aboutText {
  position: relative;
  width: 100%;
  margin-top: 40vh;
  text-align: center;
  font-size: 120px;
  font-family: "town bold", sans-serif;
  color: $textColor;
}

.callToActionBtn {
  position: relative;
  display: inline-block;
  margin-top: 40px;
  cursor: pointer;

  div {
    z-index: 1;
    font-family: "town reg";
    font-size: 12px;
    letter-spacing: 1px;
    padding: 12px 10px;
    background-color: $secColor;
    border-radius: 7px;
    margin-left: 80px;
    width: 160px;
    text-align: center;
    transition: 0.5s ease;

    a {
      color: #f2f2f2;
      text-decoration: none;
      transition: 0.5s ease;
    }
  }
}

.callToActionBtn div:nth-child(2) {
  background-color: transparent;
  color: transparent;
  border: 1px solid $secColor;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: -1;
  transition: 0.5s ease;
}

.callToActionBtn:hover {
  div:nth-child(2) {
    top: 0px;
    left: 0px;
  }

  div:nth-child(1) {
    background-color: transparent;
    a {
      color: $secColor;
    }
  }
}

.navContainer {
  height: 100vh;
  margin-bottom: 0px;
}

.hamburger {
  display: none;
  div {
    width: 25px;
    height: 3px;
    background-color: $priColor;
    transition: all 0.3s ease;
  }
  div:nth-child(2),
  div:nth-child(3) {
    margin-top: 5px;
  }
}

.visibility {
  visibility: hidden;
}

.blackUser {
  fill: black;
}

@media (max-width: 768px) {
  .apiIntroduction {
    margin-top: 400px;
    display: none;
  }
  .apibg {
    height: 40vh;
    top: 280px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .technologybg {
    object-fit: contain;
    height: 50vh;
    margin-top: 10vh;
  }
  .blackUser {
    fill: white;
  }
  .loginMenu {
    width: 90%;
  }
  .aboutbg {
    margin-top: 10vh;
    height: auto;
    object-fit: contain;
  }

  .LastText {
    margin-top: 40px;
    margin-left: 25px;
    width: 85%;
    font-size: 15px;
  }

  .vido {
    margin-top: 5px;
  }

  .LastTextSec {
    margin-left: 25px;
    width: 85%;
    font-size: 15px;
  }

  .aboutText {
    margin-top: 150px;
    font-size: 40px;
    letter-spacing: 2px;
  }
  .blackText {
    color: white;
  }
  .callToActionBtn:hover {
    cursor: default;
    div:nth-child(2) {
      top: 0px;
      left: 35px;
    }
  }
  .navContainer {
    height: 60vh;
  }

  .callToActionBtn {
    display: block;
  }

  .callToActionBtn div {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    padding: 17px 10px;
  }

  .callToActionBtn div:nth-child(2) {
    left: 35px;
  }

  .callToActionText {
    margin-top: 40px;
    margin-left: 25px;
    div {
      font-size: 30px;
    }
  }

  .toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .toggle .line2 {
    opacity: 0;
  }

  .toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  .hamburger {
    display: block;
    margin-right: 20px;
    margin-top: 0px;
  }

  .bgImg {
    display: none;
  }

  .logo {
    img {
      width: 220px;
    }
    margin-left: 20px;
    margin-top: 20px;
  }
  .nav {
    margin-top: 0;
    box-shadow: 0px 5px 10px rgb(218, 218, 218);
    padding: 0;
    z-index: 20;
    height: 10vh;
    position: relative;
  }

  body {
    overflow-x: hidden;
  }
  .navLinks {
    position: absolute;
    right: 0px;
    height: 92vh;
    width: 60%;
    top: 10vh;
    background-color: $priColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    color: white;
  }
  .order {
    order: 5;
  }

  .loginBtn {
    order: 6;
  }

  .navLinks a {
    margin-right: 0px;
    margin-top: 50px;
    font-size: 14px;
    opacity: 0;
  }

  .navLinks div {
    margin-right: 0px;
    margin-top: 50px;
    font-size: 14px;
    opacity: 1;
  }

  .scrolled {
    padding: 0;
    background-color: white;
  }

  .navActive {
    transform: translateX(0%);
  }

  @keyframes navLinkFade {
    from {
      opacity: 0;
      transform: translateX(50px);
    }

    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}
