.StripeElement {
  height: 55px;
  padding: 18px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 4px;

  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  border: 2px solid #0240af;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
