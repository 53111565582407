@import "../../App";

.contactTitle {
  font-family: "town med", sans-serif;
  font-size: 25px;
  width: 100%;
  text-align: center;
  margin-top: 60px;
  color: $textColor;
}

@media (max-width: 768px) {
  .contactTitle {
    font-size: 17px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
  }
}
