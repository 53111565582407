@import "../../App.scss";

.UserFile {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 90px;
  background-color: $priColor;
}

.header {
  width: 100%;
  color: white;
  font-size: 25px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout {
  margin-right: 30px;
  fill: white;
  cursor: pointer;
}

.fileLinks {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}

.fileLinks:hover {
  color: $priColor;
}

.userEmail {
  margin-top: 30px;
}

.fileLinksHolder {
  margin-top: 30px;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
