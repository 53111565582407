@import "../../App.scss";

.add {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  div {
    font-size: 25px;
    font-family: "town bold", sans-serif;
    margin-left: 5px;
  }
  img {
    width: 35px;
  }
}

.fileWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modalWindow:focus {
  outline: none;
}

.loginLink {
  color: $priColor;
}

#customBtn:focus {
  outline: none;
}

.fileCon {
  position: relative;
  padding: 80px;
  height: 300px;
  width: 100%;
  border-radius: 10px;
  border: 2px dashed #c2cdda;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.pdfFile {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 300px;
  z-index: 1;
}
.uploadIcon {
  width: 120px;
  fill: $priColor;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadText {
  font-family: "town reg", sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
}

.fileName {
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: #fff;
  padding: 12px 0;
  font-size: 13px;
  font-family: "town reg", sans-serif;
  letter-spacing: 1px;
  background-color: $priColor;
  text-align: center;
  z-index: 1000;
}

#cancelBtn {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  fill: $priColor;
  z-index: 1000;
}

.customBtnCon {
  width: 100%;
}

#customBtn {
  margin-top: 30px;
  width: 100%;
  height: 40px;
  display: block;
  border: none;
  border-radius: 25px;
  color: #fff;
  font-size: 15px;
  font-family: "town reg", sans-serif;
  letter-spacing: 1px;
  background-color: $priColor;
  cursor: pointer;
  transition: 0.5s ease;
}

#uploadBtn {
  text-align: center;
  margin-top: 10px;
  width: 100%;
  height: 40px;
  display: block;
  border: none;
  border-radius: 25px;
  color: #fff;
  font-size: 15px;
  font-family: "town reg", sans-serif;
  letter-spacing: 1px;
  background-color: $priColor;
  transition: 0.5s ease;
  opacity: 0.5;
  transition: 0.5 ease;
  cursor: pointer;
}

#uploadBtn:focus {
  outline: none;
}

@media (max-width: 768px) {
  .add div {
    font-size: 17px;
    text-align: center;
  }
}
