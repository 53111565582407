@import "../../App";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.feature {
  position: relative;
  margin-top: 50px;
  margin-left: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  img {
    width: 60px;
  }
  div {
    font-family: "town bold", sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $secColor;
  }
}

.featureSec {
  position: relative;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.featureSecMob {
  display: none;
}

.featureImg {
  width: 75%;
}

.techLines {
  position: absolute;
  top: -130px;
  left: 0;
  z-index: -1;
  width: 55%;
}

.why {
  margin-top: 150px;
}

.whySection {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
  img {
    position: absolute;
    top: -20px;
    left: 80px;
    width: 120px;
  }
}

.whyLineLeft {
  justify-self: flex-start;
  width: 50%;
  height: 5px;
  background-color: $priColor;
  margin-bottom: 30px;
}

.whyLineRight {
  margin-left: auto;
  width: 50%;
  height: 5px;
  background-color: $priColor;
  margin-top: 30px;
}

.whyText {
  font-family: "town med", sans-serif;
  font-size: 19px;
  color: $textColor;
  letter-spacing: 1px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.freeTrialSec {
  height: 33vh;
  margin-top: 150px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 1150px;
  }
}

.freeTrialText {
  font-family: "town light", sans-serif;
  font-size: 19px;
  color: #f2f2f2;
  letter-spacing: 5px;
  line-height: 40px;
  width: 40%;
  margin-left: 100px;
  text-align: center;
}

.freeTrialBtn {
  position: relative;
  margin-top: 40px;
  margin-right: 100px;
  text-decoration: none;

  div {
    font-family: "town reg";
    font-size: 15px;
    letter-spacing: 1px;
    color: #f2f2f2;
    padding: 15px 10px;
    background-color: $secColor;
    border-radius: 7px;
    width: 250px;
    text-align: center;
    transition: 0.5s ease;
  }
}

.freeTrialBtn div:nth-child(2) {
  background-color: transparent;
  color: transparent;

  border: 1px solid $secColor;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: -1;
  transition: 0.5s ease;
}

.freeTrialBtn:hover {
  div:nth-child(2) {
    top: 0px;
    left: 0px;
    color: transparent;
  }

  div:nth-child(1) {
    background-color: transparent;
    color: $secColor;
  }
}

.secondFeatureSec {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 150px;
}

.dashboard,
.code,
.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;

  div {
    p {
      font-family: "town med";
      font-size: 16px;
      text-transform: capitalize;
      text-align: center;
      color: $textColor;
      margin-top: 15px;
      letter-spacing: 1.5px;
    }
    width: 260px;
    height: 95px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 10px rgb(199, 199, 199);
  }
  svg {
    fill: #ffffff;
  }
  span {
    padding: 60px 90px;
    background-color: $secColor;
    box-shadow: 0px 5px 10px rgb(199, 199, 199);
  }
}

.packages {
  display: flex;
  align-items: center;
  margin-top: 150px;
  justify-content: space-between;
}

.packageTitle {
  font-size: 19px;
  font-family: "town bold", sans-serif;
  color: white;
  letter-spacing: 2px;
  word-spacing: 2px;
  margin-bottom: 20px;
}

.priceArea {
  display: flex;
  align-items: flex-start;
}
.usd {
  font-size: 19px;
  font-family: "town bold", sans-serif;
  color: white;
  letter-spacing: 1px;
  word-spacing: 2px;
  margin-bottom: 30px;
}
.dollarSign {
  font-size: 19px;
  font-family: "town bold", sans-serif;
  color: white;
  letter-spacing: 1px;
  margin-top: 7px;
  margin-right: 5px;
}

.dollarAmount {
  font-size: 43px;
  font-family: "town bold", sans-serif;
  color: white;
  letter-spacing: 1px;
}

.monthly,
.free,
.enterprise {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.monthly,
.free {
  width: 425px;
  height: 75vh;
  margin-left: 0;
}

.enterprise {
  padding-bottom: 30px;
  border: 3px solid $priColor;
}

.enterpriseTitle {
  font-size: 19px;
  font-family: "town bold", sans-serif;
  color: $priColor;
  letter-spacing: 2px;
  word-spacing: 2px;
  margin-top: 25px;
  margin-bottom: 20px;
}

.enterpriseNum {
  font-size: 17px;
  font-family: "town black", sans-serif;
  color: $priColor;
  letter-spacing: 2px;
  word-spacing: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.enterpriseDesc {
  font-size: 13px;
  font-family: "town med", sans-serif;
  color: $priColor;
  letter-spacing: 1px;
  margin-left: 5px;
  text-align: center;
  width: 75%;
}

.packageFeaturesCont {
  margin-left: 15px;
}

.packageFeaturesContEnterprise {
  margin-top: 25px;
  margin-bottom: 10px;
}

.packageFeatures {
  margin-top: 5px;
  margin-right: 5px;
  span {
    font-size: 13px;
    font-family: "town reg", sans-serif;
    color: white;
    letter-spacing: 1px;
    margin-left: 5px;
  }
  svg {
    fill: #ffffff;
  }
}

.enterpriseFeatures {
  margin-top: 5px;
  span {
    font-size: 13px;
    font-family: "town med", sans-serif;
    color: $priColor;
    letter-spacing: 1px;
    margin-left: 5px;
  }
  svg {
    fill: $priColor;
  }
}

.packageFeaturesContFree {
  margin-left: 0px;
}

.cartBtn {
  cursor: pointer;
  position: relative;
  margin-top: 30px;
  text-decoration: none;
  div {
    font-family: "town reg";
    font-size: 15px;
    letter-spacing: 1px;
    color: #f2f2f2;
    padding: 12px 12px;
    background-color: $secColor;
    border-radius: 40px;
    width: 180px;
    text-align: center;
    transition: 0.5s ease;
  }
}

.cartBtn div:nth-child(2) {
  background-color: transparent;
  color: transparent;
  border: 1px solid $secColor;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: -1;
  transition: 0.5s ease;
}

.cartBtn:hover {
  div:nth-child(2) {
    top: 0px;
    left: 0px;
  }

  div:nth-child(1) {
    background-color: transparent;
    color: $secColor;
  }
}

.fillFormBtn {
  margin-top: 5px;
}

@media (max-width: 768px) {
  .monthly,
  .free {
    height: 95vh;
  }
  .packageFeaturesCont {
    margin-left: 35px;
    margin-right: 25px;
  }
  .packagesBg {
    display: none;
  }

  .packageTitle {
    margin-top: 30px;
  }

  .packagesBgRight {
    display: none;
  }
  .packages {
    flex-direction: column;
  }

  .enterprise {
    margin-bottom: 15px;
    width: 350px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 15px;
  }

  .secondFeatureSec {
    flex-direction: column;
    margin-top: 150px;
    align-items: center;
  }

  .dashboard,
  .code,
  .upload {
    margin-top: 40px;
  }
  .freeTrialBtn {
    margin-right: 0px;
    div {
      width: 330px;
    }
  }
  .freeTrialText {
    font-size: 16px;
    margin-left: 0px;
    width: 80%;
    letter-spacing: 3px;
    margin-top: 50px;
  }
  .freeTrialSec {
    margin-top: 50px;
    flex-direction: column;
    img {
      height: 350px;
      width: 100%;
    }
  }
  .whyText {
    font-size: 16px;
    width: 80%;
    font-weight: "town reg", sans-serif;
    margin-top: 10px;
  }
  .whySection {
    img {
      width: 60px;
      left: 5px;
      top: -5px;
    }
  }

  .why {
    margin-top: 50px;
  }

  .feature {
    margin-top: 200px;
    margin-left: 25px;
    img {
      width: 40px;
    }
  }

  .featureSec {
    display: none;
  }

  .featureSecMob {
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
    }
    div {
      font-family: "town reg";
      font-size: 16px;
      letter-spacing: 2px;
      margin: 20px 25px 40px 25px;
    }
  }
}
