@import "../../App";

@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,500;1,700&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.aboutSecOneTitle {
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-family: "town reg", sans-serif;
  color: $textColor;
  margin-bottom: 5px;
  letter-spacing: 2px;
}

.aboutSecOneline {
  height: 2px;
  background-color: $secColor;
  width: 23%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.aboutSecTwo {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 150px;
  flex-wrap: wrap;
}

.aboutSecTwoCont {
  width: 20%;
  background-color: white;
  box-shadow: 6px 6px 12px 5px rgb(241, 241, 241);
  display: flex;
  flex-direction: column;
  img {
    width: 120px;
    height: 120px;
    align-self: flex-end;
    margin-right: 40px;
    margin-top: 40px;
  }
}

.aboutQuestion {
  font-family: "town bold", sans-serif;
  letter-spacing: 2px;
  margin-left: 30px;
  margin-top: 50px;
}

.aboutSecOne {
  width: 100%;
  background-color: $priColor;
  padding: 30px 140px;
  text-align: center;
  font-family: "town reg", sans-serif;
  font-size: 18px;
  color: white;
  letter-spacing: 2px;
  word-spacing: 3px;
  line-height: 28px;
}

.aboutMargin {
  margin-top: 150px;
}

.aboutSecThree {
  position: block;
  margin-top: 50px;
  width: 50%;
  margin-left: auto;
  padding-top: 25px;
  padding-bottom: 25px;
}

.infoTitle {
  font-family: "town reg", sans-serif;
  font-size: 22px;
  letter-spacing: 2px;
  color: $secColor;
  padding-left: 20px;
}

.infoPara {
  font-family: "town reg", sans-serif;
  font-size: 15px;
  letter-spacing: 2px;
  color: $textColor;
  padding: 20px;
  line-height: 20px;
}

.note {
  margin-top: 150px;
  width: 75%;
  color: #f2f2f2;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  font-family: "town reg", sans-serif;
  font-size: 18px;
  padding: 5px;
  letter-spacing: 2px;
  background-color: $priColor;
}

.aboutSecFour {
  padding: 25px;
  border: 2px solid $priColor;
  font-family: "town reg", sans-serif;
  letter-spacing: 2px;
  font-size: 19px;
  width: 75%;
  color: $priColor;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.aboutSecFive {
  margin-top: 150px;
  display: flex;
  justify-content: space-evenly;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  flex-wrap: wrap;
}

.aboutSecFiveOne,
.aboutSecFiveTwo,
.aboutSecFiveThree,
.aboutSecFiveFour {
  width: 200px;
  font-family: "town reg", sans-serif;
  letter-spacing: 2px;
  color: $priColor;
  img {
    margin-bottom: 30px;
    width: 80px;
  }
}

.aboutSecFiveTwo,
.aboutSecFiveThree {
  margin-top: 80px;
  margin-top: 80px;
}

.aboutSecSix {
  margin-top: 150px;
  font-family: "town reg", sans-serif;
  font-size: 20px;
  letter-spacing: 2px;
  color: $textColor;
  padding: 25px;
  box-shadow: 0px 5px 6px 5px rgb(236, 236, 236);
  width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.aboutSecSeven {
  margin-top: 150px;
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}

.aboutSecSevenDetial {
  font-family: "town bold", sans-serif;
  font-size: 22px;
  margin-left: 100px;
  letter-spacing: 2px;
  margin-bottom: 40px;
}

.aboutSecSevenFeature {
  font-family: "town reg", sans-serif;
  font-size: 19px;
  margin-left: 100px;
  letter-spacing: 2px;
  margin-top: 20px;
}
.aboutSecSevenLine {
  width: 15px;
  height: 350px;
  background-color: $priColor;
}

.aboutSecSevenTitleSec {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutSecSevenTitle {
  font-family: "town bold", sans-serif;
  font-size: 35px;
  margin-left: 20px;
}

.aboutSecEight {
  margin-top: 150px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  .qoute {
    font-family: "Cormorant", sans-serif;
    font-size: 34px;
  }

  .qouteOwner {
    margin-top: 30px;
    font-family: "Cormorant", sans-serif;
    font-weight: 700;
    font-size: 34px;
  }
}

@media (max-width: 768px) {
  .aboutSecTwo {
    flex-direction: column;
  }
  .aboutSecTwoCont {
    width: 90%;
    height: auto;
    margin-bottom: 40px;
  }
  .aboutSecEight {
    .qoute {
      font-size: 24px;
    }
    .qouteOwner {
      font-size: 22px;
    }
  }
  .aboutSecSevenDetial {
    font-size: 17px;
    margin-left: 50px;
    margin-bottom: 20px;
  }
  .aboutSecSevenFeature {
    margin-top: 10px;
    font-size: 16px;
    margin-left: 50px;
  }
  .aboutSecSix {
    font-size: 16px;
    width: 90%;
  }
  .aboutSecFiveOne,
  .aboutSecFiveTwo,
  .aboutSecFiveThree,
  .aboutSecFiveFour {
    font-size: 15px;
    margin-top: 60px;
  }

  .note {
    font-size: 16px;
    width: 90%;
  }

  .aboutSecFour {
    font-size: 15px;
    width: 90%;
  }
  .aboutSecThree {
    width: 75%;
  }

  .infoTitle {
    font-size: 16px;
  }

  .infoPara {
    font-size: 15px;
  }
  .aboutSecOne {
    padding: 30px 30px;
  }

  .aboutSecOneTitle {
    font-size: 16px;
  }

  .aboutParas {
    font-size: 15px;
    line-height: 15px;
    word-spacing: 2px;
  }

  .aboutParaOne {
    font-size: 18px;
  }
}
