@import "../../App";

.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.footerHolder {
  margin-top: 300px;
  background-color: $priColor;
}

.footerLogo {
  margin-left: 50px;
  margin-top: 40px;
}

.footerLineOne {
  width: 2px;
  background-color: $secColor;
  height: 30vh;
  margin-top: 60px;
  margin-bottom: 35px;
}

.footerSupport div:nth-child(1) {
  font-family: "town bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  color: $secColor;
  margin-top: 60px;
}

.footerSupport div:nth-child(2),
.footerSupport div:nth-child(3) {
  font-family: "town reg", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  margin-top: 20px;
  cursor: pointer;
  a {
    color: white;
    text-decoration: none;
  }
}

.footerLinks div:nth-child(1) {
  font-family: "town bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  color: $secColor;
  margin-top: 60px;
}

.footerLinks div:nth-child(2),
.footerLinks div:nth-child(3),
.footerLinks div:nth-child(4),
.footerLinks div:nth-child(5) {
  font-family: "town reg", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  margin-top: 20px;
  margin-right: 150px;
  a {
    color: white;
    text-decoration: none;
  }
}

.copyright {
  opacity: 0.4;
  text-align: center;
  top: 0;
  font-family: "town med", sans-serif;
  letter-spacing: 2px;
  font-size: 15px;
  padding-bottom: 10px;
  color: white;
}

@media (max-width: 768px) {
  .copyright {
    margin-top: 50px;
    font-size: 12px;
  }
  .footerLinks div:nth-child(2),
  .footerLinks div:nth-child(3),
  .footerLinks div:nth-child(4),
  .footerLinks div:nth-child(5) {
    margin-right: 0px;
    margin-top: 20px;
    width: 30%;
  }

  .footerLinks div:nth-child(1) {
    margin-top: 20px;
    width: 30%;
  }

  .footerSupport {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .footerLinks {
    width: 90%;
    display: flex;
    margin: 0;
    margin-top: 40px;
    padding: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
  }
  .footerSupport div:nth-child(1) {
    font-size: 12px;
    margin-top: 60px;
  }
  .footerSupport div:nth-child(2) {
    margin-top: 60px;
  }
  .footerSupport div:nth-child(3) {
    margin-top: 60px;
  }
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .footerLogo {
    margin-left: 0;
    margin-top: 20px;
    img {
      width: 200px;
    }
  }
  .footerLineOne {
    display: none;
  }
}
