@import "../../App.scss";

.titleSec {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 100px;
}
.underline {
  width: 300px;
  height: 2px;
  background-color: $priColor;
  margin: 0 30px;
}

.worldClass {
  text-align: center;
  font-size: 30px;
  font-family: "town reg", sans-serif;
  letter-spacing: 2px;
}

.technologyIsometric {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 100px;
  img {
    width: 40%;
  }
}

.technoTitle {
  font-size: 30px;
  font-family: "town bold", sans-serif;
  width: 100%;
}

.technoTitleBlue {
  font-size: 40px;
  font-family: "town bold", sans-serif;
  width: 100%;
  color: $priColor;
}

.subText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.technoBgSec {
  width: 100%;
  position: relative;
  height: 300px;

  img {
    height: 300px;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.technoBgSec {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-bottom: 150px;
  padding: 30px 0;

  div {
    color: white;
    border: 2px solid white;
    padding: 20px;
    font-size: 17px;
    font-family: "town reg", sans-serif;
    letter-spacing: 2px;
    text-align: center;
    width: 25%;
    line-height: 38px;
  }
}

.technoBg {
}

.techSec3 {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.secHolder {
  text-align: center;
  img {
    width: 100px;
  }
  div {
    margin-top: 20px;
    width: 300px;
    letter-spacing: 2px;
    font-size: 20px;
    font-family: "town reg", sans-serif;
    color: $priColor;
  }
}

.techSec4 {
  margin-top: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.keyFeatures {
  font-family: "town bold", sans-serif;
  font-size: 20px;
  width: 80%;
  line-height: 35px;
  letter-spacing: 2px;
  margin-bottom: 50px;
  color: $priColor;
}

.techSec4bg {
  width: 50%;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .techSec4bg {
    width: 79%;
    margin-bottom: 30px;
  }

  .techSec4 {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .keyFeatures {
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
  .techSec3 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .secHolder {
    img {
      width: 50px;
    }
    div {
      font-size: 15px;
      margin-bottom: 40px;
    }
  }
  .worldClass {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .underline {
    display: none;
  }
  .technologyIsometric {
    flex-direction: column;
    img {
      margin-top: 30px;
      width: 80%;
    }
  }

  .technoTitle {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .technoTitleBlue {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .technoBgSec {
    height: 100vh;
    flex-direction: column;
    margin-bottom: 50px;
    div {
      padding: 5px;
      font-size: 15px;
      height: auto;
      width: 90%;
    }
    img {
      height: 100vh;
    }
  }
}
