@import "../../App.scss";

.pricingTitle {
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-family: "town reg", sans-serif;
  letter-spacing: 2px;
}

.packageMargin {
  margin-top: 50px;
}

.checkoutHolder {
  width: 100%;
}

.trialHolder {
  width: 50%;
  height: 50%;
  background-color: white;
  padding: 15px;
}

.trialHolder:focus {
  outline: none;
}

.trialLine {
  width: 100%;
  height: 100%;
  border: 2px solid $secColor;
  padding: 20px;
}

.trialSure {
  font-size: 20px;
  font-family: "town reg", sans-serif;
  text-align: center;
  margin-top: 50px;
}

.selection {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .trialHolder {
    width: 90%;
  }
  .trialSure {
    font-size: 16px;
    letter-spacing: 2px;
  }

  .selection {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}
